.one-media{
    width: 100%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}

.two-media{
    width: 99%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}

.three-first-media{
 width: 98%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}

.three-media{
     width: 95%;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;

}

.vid-img{
     position: relative;


}

.vid-img .arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  border: none;
  cursor: pointer;
  border-radius: 5px;
width:30px;
height: 30px
}
