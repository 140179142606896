.submit-search {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent url("../../images/vectors/search.svg") no-repeat
    center;
  opacity: 0.7;
}

.submit-search:hover {
  opacity: 1;
}

@media only screen and (max-width: 1050px) {

  .sidebar .name-link {
    display: none;
  }
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 25%;
  position: absolute;
  height: 100%;
  overflow: auto;
  background-color: #222338;
}

.sidebar span {
  display: block;
  color: rgb(255, 255, 255);
  padding: 10px;
  text-decoration: none;
}

.sidebar a {
  color: #ffffff;
}
div.content {
  margin-left: 30%;
  padding: 1px 16px;
  height: 1000px;
}
.sidebar-post1 {
  margin-top: 80px;
}

.sidebar-post,
.sidebar-post1 {
  margin-left: 60px;
}
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .sidebar-post,
  .sidebar-post1 {
    width: 18%;
  }
  .sidebar span {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
  .sidebar .name-link {
    display: none;
  }
  .sidebar-post1 {
    margin-top: 0px;
  }

  .sidebar-post,
  .sidebar-post1 {
    margin-left: 0px;
  }
}

.sidebar .name-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  align-items: center;

  color: rgba(255, 255, 255, 0.8);
}
.header-layout {
  background-color: #222338;
}

@media screen and (max-width: 767px) {

  .about-link {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .sidebar span {
    text-align: center;
    float: none;
  }
  .about-link {
    display: none;
  }
 
}
/* /////////////////////////////////// */
/* Import Google Font - Poppins */
.post {
  width: 61%;
  height: 217px;
  background-color: #222338;
  justify-content: center;
}
.devide {
  width: 95%;
}

.your-mind {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 15px;
  height: 87px;
}
.your-mind textarea {
  width: 80%;
  border: none;
  outline: none;
  background-color: #222338;
  padding: auto;
  margin-left: 10px;
  resize: none;
}
.your-mind img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.btn-wyspp {
  border-radius: 50%;
  border: none;
  background-color: #222338;
  color: #2d7fb6;
  float: right;
  width: 120px;
  height: 32px;
}
.line-img {
  width: 80%;
}
.about-link {
  height: 18px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.8);
}

.home {
  width: 57px;
  height: 29px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #ffffff;
  padding: 10px;
  margin-left: 20px;
}
.toptweet{
    margin-left: 15px;
}


