/***********IntroPage***********/
.smallScreenBgSize {
  background-image: url("../../images/bg-with-mobile.png");
  background-position-x: 93%;
  background-position-y: 60%;
  background-repeat: no-repeat;
  background-size: 38% auto;
}
.very-big-text {
  font-size: clamp(2rem, 3.5vw, 4rem);
  line-height: clamp(3rem, 5vw, 6rem);
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  max-width: clamp(20.5rem, 43.1vw, 51.8rem);
}
.big-text {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 2%;
  margin-bottom: 7%;
  max-width: 821px;
}
.download-app-btn {
  width: clamp(7rem, 13.4vw, 11rem);
  margin-right: 20px;
  margin-bottom: 5px;
}
.intro-img {
  max-height: 620px;
  padding-top: 0%;
  display: none;
}
.Intro-big-text {
  width: clamp(30rem, 43.17vw, 51rem);
}
/*********************Video Page****************/
.intro-wisp-img-div {
  justify-content: center;
}
.Intro-section-text-width {
  line-height: clamp(1.8rem, 1.8vw, 2.25rem);
  font-size: clamp(1rem, 1.22vw, 1.5rem);
  max-width: clamp(30.66rem, 38.5vw, 46rem);
  margin-bottom: auto;
  margin-top: auto;
}
/*****************Features Page***********************/
.feature-text {
  margin-top: 15%;
}
.feature-icon-img {
  display: inline !important;
  max-width: 60px;
  max-height: 65px;
  margin-right: 2%;
  margin-top: -2%;
}
.italic-title {
  font-size: 32px;
  font-style: Poppins;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  display: inline;
}
/*************************** Mail Subscription*************/
.mail-subscription-container {
  background-color: rgba(238, 241, 243, 0.1);
  padding-top: 70px;
  padding-left: 10%;
  padding-right: 8%;
  margin-bottom: 15px;
  height: 339px;
}
.mail-bg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative !important;
}
.column-pt {
  padding-top: 16px;
}

.mail-subscription-container {
  height: 341px;
}
.mail-form-container {
  background: #ffffff;
  box-shadow: 0px 22.7px 60.5333px rgba(47, 65, 88, 0.2);
  border-radius: 8px;
  padding-left: 25px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 0px;
  width: 31rem;
}
.after-subscribe-container {
  height: 48px;
  padding-top: 10px;
}
.after-subscribe-span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  color: #090b22;
}
.email-input-text {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: black !important;

  height: 48px;
  appearance: none;
  background-color: rgba(227, 227, 227, 0.5);
  box-shadow: inset 0px 0px 0px 0px red;
  border: none;
}

.email-inner-submit-btn {
  background-color: #f02657 !important;
  border: none;
  width: 100px;
  position: relative;
  left: -5;
  z-index: 10;
  background-color: #f02657;
  box-shadow: 0px 3.78333px 18.9167px rgba(110, 109, 192, 0.5);
  border-radius: 8px;
  height: 100%;
}
.email-inner-submit-btn div {
  margin-left: 13px;
}
.email-button-text {
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 30px !important;
  color: #ffffff;
}
.text-danger {
  font-size: 10px;
  height: 18px;
}
.bottom-section-icon {
  width: 26vw;
  bottom: -48px;
  height: 124px;
}
.bottom-section-icon-container {
  position: absolute;
}
.left-section-icon {
  width: 28vw;
}

.left-section-icon-container {
  position: absolute;
  left: -4%;
  bottom: -80px;
}

.right-section-icon {
  width: 26vw;
  top: -40%;
}
.right-section-icon-container {
  position: absolute;
  top: -70px;
  right: -36px;
}

.mail-title {
  font-size: 23px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
.mail-subtitle {
  color: #b2afb6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.top-section-icon-container {
  position: absolute;
  right: 66px;
  top: -25px;
}

/************************FAQ Page******************************/
.FAQ-container {
  margin-bottom: 50px;
  padding-left: 3%;
}
#Faq-According-item {
  margin-top: 5px;
  background-color: rgba(238, 241, 243, 0.1);
  border-radius: 8px;
  border: none;
}
#Faq-According-Header {
  font-weight: 400;
  font-size: 25px;
  border: none;
  border-radius: 8px;
}
#Faq-According-body {
  color: white;
  font-weight: 400;
  font-size: 18px;
  white-space: pre-line;
}
@media only screen and (max-width: 1550px) {
  .mail-form-container {
    width: 32vw;
  }

  .right-section-icon-container {
    top: -70;
    right: -25;
  }
}
@media only screen and (max-width: 1549px) and (min-width: 1408px) {
  .left-section-icon {
    width: 27vw;
  }

  .left-section-icon-container {
    left: -2%;
    bottom: -80px;
  }
}
@media (min-width: 1400px) and (max-width: 1500px) {
  .big-text {
    max-width: 813px;
  }
}
@media only screen and (max-width: 1408px) and (min-width: 1380px) {
  .left-section-icon {
    width: 316px;
  }

  .left-section-icon-container {
    left: 0%;
    bottom: -94px;
  }
}
@media (min-width: 1200px) and (max-width: 1401px) {
  .big-text {
    font-size: 22px;
    line-height: 34px;
    max-width: 750px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 1200px) {
  .left-section-icon {
    width: 27vw;
  }

  .left-section-icon-container {
    left: -5%;
    bottom: -90px;
  }
  .mail-subscription-container {
    height: 339px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1050px) {
  .left-section-icon {
    width: 295px;
  }

  .left-section-icon-container {
    left: -3%;
    bottom: -98px;
  }
  .mail-subscription-container {
    height: 339px;
  }
}
@media only screen and (max-width: 1050px) and (min-width: 1000px) {
  .left-section-icon {
    width: 27vw;
  }

  .left-section-icon-container {
    left: -4%;
    bottom: -104px;
  }
  .mail-subscription-container {
    height: 339px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 872px) {
  .mail-subscription-container {
    height: 295px;
  }

  .left-section-icon {
    width: 188px;
  }

  .right-section-icon {
    width: 20vw;
  }
  .mail-subscription-container {
    height: 295px;
  }

  .left-section-icon-container {
    left: -4%;
    bottom: -90px;
  }

  .right-section-icon-container {
    right: -3%;
    top: -16px;
  }
  .mail-form-container {
    width: 44vw;
  }
}

@media only screen and (max-width: 872px) and (min-width: 768px) {
  .mail-subscription-container {
    height: 295px;
  }

  .left-section-icon {
    width: 181px;
  }

  .right-section-icon {
    width: 20vw;
  }
  .mail-subscription-container {
    height: 291px;
  }

  .left-section-icon-container {
    left: -4%;
    bottom: -88px;
  }

  .right-section-icon-container {
    right: -3%;
    top: -16px;
  }
  .mail-form-container {
    width: 44vw;
  }
}

@media only screen and (max-width: 767px) {
  .mail-subscription-container {
    height: 295px;
  }

  .left-section-icon {
    width: 22vw;
  }
  .mail-subscription-container {
    height: 384px;
  }

  .left-section-icon-container {
    display: none !important;
  }
  .right-section-icon-container {
    display: none !important;
  }

  .intro-wisp-img {
    max-width: 100%;
    width: 100%;
  }
}

@media (min-width: 801px) and (max-width: 1399px) {
  .smallScreenBgSize {
    background-position-y: 40%;
    background-size: 40%;
  }
  .intro-img {
    margin-right: 4%;
    max-height: 80vh;
  }
}
@media (max-width: 800px) {
  .big-text {
    font-size: clamp(1.5rem, 2vw, 3rem);
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    margin: 5% auto;
  }

  .smallScreenBgSize {
    background-image: none;
  }
  .Intro-big-text {
    margin: auto;
    text-align: center;
  }
  /* .very-big-text {
    margin: auto;
  } */
  .intro-img {
    display: block;
  }
}
@media (max-width: 500px) {
  .intro-2nd-text {
    margin-top: 2rem;
  }
  .big-text {
    font-size: medium;
    line-height: clamp(2rem, 2vw, 36px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    max-width: 100%;
    margin: 5% auto;
  }
}

@media only screen and (min-width: 992px) {
  .info-phone-img {
    padding-right: 12%;
    padding-top: -40 !important;
  }
}
@media (min-width: 1700px) {
  .intro-img {
    margin-right: -15%;
  }
}
@media (max-width: 992px) {
  .Intro-section-text-div {
    font-size: 12px;
    width: 100%;
    justify-content: center;
  }
  .feature-text {
    margin-top: 1% !important;
  }
  .intro-wisp-img {
    /* max-width: clamp(25rem, 32.2vw, 38rem); */
    width: 30rem !important;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .big-text {
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .big-text {
    font-size: 18px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 490px) and (min-width: 388px) {
  .bottom-section-icon {
    width: 45vw;
  }
  .top-section-icon-container {
    right: -8px;
  }

  .mail-title {
    font-size: 19px;
  }

  .bottom-section-icon-container {
    left: -9px;

    bottom: -126px;
  }

  .email-inner-submit-btn {
    background-color: #f02657;
  }

  .after-subscribe-container {
    height: 48px;
  }
}
@media only screen and (max-width: 388px) and (min-width: 375px) {
  .bottom-section-icon {
    width: 330px;
  }
  .top-section-icon-container {
    right: -8px;
  }

  .mail-title {
    font-size: 19px;
  }

  .bottom-section-icon-container {
    left: -70px;
    bottom: -132px;
  }

  .email-input-text {
    height: 40px;
  }

  .email-inner-submit-btn {
    background-color: #f02657;
  }

  .after-subscribe-container {
    height: 40px;
  }

  .mail-submit-btn {
    height: 40px;
    background-color: #f02657;
  }
}
@media only screen and (max-width: 375px) and (min-width: 355px) {
  .mail-form-container {
    width: 19rem !important;
  }

  .bottom-section-icon {
    width: 50vw;
  }
  .top-section-icon-container {
    right: -8px;
  }

  .mail-title {
    font-size: 16px;
  }

  .bottom-section-icon-container {
    left: 0px;
    bottom: -133px;
  }

  .email-input-text {
    height: 40px;
  }

  .email-inner-submit-btn {
    height: 40px;
    background-color: #f02657;
  }

  .after-subscribe-container {
    height: 40px;
  }

  .mail-submit-btn {
    height: 40px;
    background-color: #f02657;
  }
}
@media (max-width: 767px) {
  .mail-form-container {
    width: 25rem !important;
  }
}
@media (max-width: 500px) {
  .mail-form-container {
    width: 22rem !important;

    padding-left: 15px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .mail-form-container {
    width: 90vw !important;
  }
  .email-form-input {
    width: 100% !important;
  }
}
@media (max-width: 300px) {
  .email-inner-submit-btn {
    width: 80px;
    height: auto;
    margin-top: 2%;
  }
  .email-input-text {
    width: 90% !important;
    border-radius: 10px !important;
  }
  .mail-btn-div {
    width: 100%;
    text-align: right;
  }
  .intro-button-div {
    width: 100vw;
  }
}

@media only screen and (max-width: 604px) and (min-width: 525px) {
  .bottom-section-icon {
    width: 32vw;
  }
  .top-section-icon-container {
    right: 5px;
  }
  .bottom-section-icon-container {
    position: absolute;
    bottom: -125px;
    left: 1px;
  }
}

@media only screen and (max-width: 525px) and (min-width: 490px) {
  .bottom-section-icon {
    width: 32vw;
  }
  .top-section-icon-container {
    right: 4px;
  }

  .bottom-section-icon-container {
    position: absolute;
    bottom: -133px;
    left: -13px;
  }
}
@media (max-width: 355px) {
  .top-section-icon-container {
    display: none;
  }
  .bottom-section-icon-container {
    left: 0px;
    bottom: -109px;
  }
  .bottom-section-icon {
    width: 45vw;
  }
}
@media only screen and (max-width: 765px) and (min-width: 605px) {
  .bottom-section-icon {
    width: 32vw;
  }
  .top-section-icon-container {
    right: 5px;
    top: -16px;
  }
  .bottom-section-icon-container {
    position: absolute;
    bottom: -125px;
    left: 1px;
  }
}

@media only screen and (max-width: 1379px) and (min-width: 1250px) {
  .left-section-icon {
    width: 302px;
  }

  .left-section-icon-container {
    left: 0;
    bottom: -98px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1050px) {
  .left-section-icon {
    width: 295px;
  }

  .left-section-icon-container {
    left: -3%;
    bottom: -98px;
  }
  .mail-subscription-container {
    height: 339px;
  }
}
