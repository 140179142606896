.sidebar-logo{
    width:85%;
}

.sidebar-item-title{
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 25px;

display: flex;
align-items: center;

color: #FFFFFF;
}


.sidebar-item-icon{
width: 3rem !important;
margin-right: 30px;
}


.vertical-sidebar-item-icon{
width: 3rem !important;
margin-right: 5px;
}


.navbar{
    background-color: rgba(255, 255, 255, 0.1); 
}


.searchbar{
    background-color: rgba(255, 255, 255, 0.1) !important;
    box-shadow: 0px 4px 20px rgba(3, 16, 52, 0.5) !important;
    border-radius: 25px !important;
    border: none !important;
}














@media (max-width: 576px) {
.sidebar-item{
    margin-left: 20px;
    margin-bottom: 30px;
}

.sidebar-logo{
    width:35%;
}
}






.search-input{
    border: none !important;
background: rgba(255, 255, 255, 0.1) !important;
box-shadow: 0 !important;
height: 100%;
}

.search-left{
border-top-right-radius: 25px !important;
border-bottom-right-radius: 25px !important;

}

.search-right{
border-top-left-radius: 25px !important;
border-bottom-left-radius: 25px !important;
}

.kpudpN{
    min-width: 100% !important;
    width: 100% !important;
}


.people-to-know-container{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin-bottom: 5px;

}


.people-to-know-title{
    font-size: 19px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}



hr.rounded {
  border-top: 2px solid white;
  border-radius: 5px;
}


.button-follow{
  
    background: url(../../images/button-bg.png) no-repeat;
    cursor:pointer;
    border: none;
    background-size: 100%;
    width: 86px;
    padding: 0px !important;
    margin: 0px  !important;
    height: 37px;
    backdrop-filter: blur(25px);
    border-radius: 24px;
    border: 1.5px solid;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%, rgba(255, 255, 255, 0.03) 100%);


}
.button-text{
font-weight: 600;
font-size: 12px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
color: #FFFFFF;
transform: matrix(1, 0, 0, 1, 0, 0);
}


.msgs-display-name{
 color:   #35AEF9;
font-weight: 600;
font-size: 18px;
line-height: 27px;
/* identical to box height */

letter-spacing: 0.05em;
}
.msgs-time{
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.05em;
text-align: left;

}