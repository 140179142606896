.static-title {
  font-size: clamp(1.5rem, 2.2vw, 2rem);
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}



#terms-content p {
  font-size: 1.2 rem;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

#terms-content h1 {
  font-size: clamp(1.2rem, 1.5vw, 1.8rem);
  line-height: clamp(2rem, 2.5vw, 30px);
    font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
  color:white;
}

#terms-content ul li {

  padding-top: 5px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  list-style-type: circle;
  color:white;
}