.display-name{
font-size: 18px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.0015em;
text-align: left;
}

.username{
font-size: 12px;
font-weight: 300;
line-height: 16px;
letter-spacing: 0.004em;
text-align: left;

}

.dots{

}
 
.time{
font-size: 10px;
font-weight: 300;
line-height: 16px;
letter-spacing: 0.004em;
text-align: right;

}

.avatar{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.post-card{
    background-color: rgba(255, 255, 255, 0.03);
    padding: 10px, 5px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
width:100%
;
}

.card-identifier{
    width: 2px
}

.wrap { 
   white-space: pre-wrap;      /* CSS3 */   
   white-space: -moz-pre-wrap; /* Firefox */    
   white-space: -pre-wrap;     /* Opera <7 */   
   white-space: -o-pre-wrap;   /* Opera 7 */    
   word-wrap: break-word;      /* IE */
   word-break: break-all;

}

.description{

font-size: 12px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
  white-space: pre-wrap;      /* CSS3 */   
   white-space: -moz-pre-wrap; /* Firefox */    
   white-space: -pre-wrap;     /* Opera <7 */   
   white-space: -o-pre-wrap;   /* Opera 7 */    
   word-wrap: break-word;      /* IE */

}