/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"); */
@import "../node_modules/react-modal-video/css/modal-video.css";

@import url("./assets/css/specialstyles/staticpages.css");

@import url("../node_modules/react-phone-number-input/style.css");

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: semi-bold;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}

body {
  font-family: "Poppins", Georgia, serif;
  background-color: #090b22;
  margin: 0;
  padding: 0;
  text-align: left;
}

.disable-scrollbars::-webkit-scrollbar {
  background: transparent;
  /* Chrome/Safari/Webkit */
  width: 0px;
}

.disable-scrollbars {
  scrollbar-width: none;
  /* Firefox *
  -ms-overflow-style: none;
  /* IE 10+ */
}

/* .smallScreenBgSize {
  background-image: url("./assets/images/bg-with-mobile.png");
  background-position-x: 93%;
  background-position-y: 60%;
  background-repeat: no-repeat;
  background-size: 38% auto;
} */
/* .intro-img {
  max-height: 620px;
  padding-top: 0%;
  display: none;
} */

/* .intro-2nd-text {
  margin-top: 5%;
  margin-bottom: 5%;
} */
/*
.Intro-section-text-width {
  line-height: clamp(1.8rem, 1.8vw, 2.25rem);
  font-size: clamp(1rem, 1.25vw, 1.5rem);
  max-width: clamp(30.66rem, 38.5vw, 46rem);
} */

.features-container {
  background-image: url("./assets/images/features-bg-dark.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80px;
}

.slick-dots {
  bottom: -30px !important;
}

.slick-dots li.slick-active button:before {
  color: #2fb2ff !important;
}

.slick-dots li button:before {
  color: white;
  opacity: 1;
}
/* .intro-wisp-img {
  max-width: 80%;
  max-height: 32.2vw;
}
 */
@media (min-width: 801px) and (max-width: 1399px) {
  /* .smallScreenBgSize {
    background-position-y: 40%;
    background-size: 40%;
  } */
  /* .intro-img {
    margin-right: 4%;
    max-height: 80vh;
  } */
  /* .intro-wisp-img {
      width: 90%;
      max-height: 20vw;
    } */
}
/* @media (max-width: 800px) {
  .intro-img {
    display: block;
  }
} */
/* @media (min-width: 1700px) {
  .intro-img {
    margin-right: -15%;
  }
} */
@media (max-width: 993px) {
  .hide-on-sm {
    display: none !important;
  }
  /* .feature-text {
    margin-top: 1% !important;
  } */
  /* .intro-wisp-img {
    max-width: 79%;
    max-height: 60vh;
    margin: auto;
    margin-top: 0%;
  } */
  /* .Intro-section-text-div {
    text-align: center;
    justify-content: center;
    font-size: 12px;
    width: 100%;
  } */
}
@media (max-width: 800px) {
  .hide-on-sm {
    display: none !important;
  }

  .navbar-collapse {
    background-color: rgb(42, 45, 71);
    padding-left: 10px;
    padding-right: 10px;
  }

  /* .Intro-big-text {
    margin: auto;
  
  } */

  .logo {
    width: 50%;
  }

  .smallScreenBgSize {
    background-image: none;
  }
  /* .intro-img {
    margin-right: -25%;
    max-width: 50vw;
  } */

  .paragraph {
    font-size: 18px !important;
    font-weight: 400;
    line-height: 28px !important;
    letter-spacing: 0em;
    text-align: left;
    color: white;
  }

  .body-text {
    font-size: clamp(1.5rem, 2.2vw, 2rem);
    line-height: clamp(2rem, 2vw, 36px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: white;
  }

  .btn-md {
    width: 100%;
    margin-right: 5px;
    border: none;
  }

  .small-text {
    /* line-height:0.3rem; */
    font-weight: 300;
    font-size: 15px !important;
    text-align: left;
    color: white;
  }

  /* .big-text {
    font-size: clamp(1.5rem, 2vw, 3rem);
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: white;
  } */

  /* .very-big-text {
    font-size: clamp(2rem, 4vw, 4rem);
    line-height: clamp(3rem, 5vw, 80px);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    max-width: 100px;
  } */

  .center-on-sm {
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
  }
}

.bottom-space {
  margin-bottom: 70px;
}

.vertical-space-sm {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.no-padding-sm {
  padding-left: 0px !important;
}

.small-text {
  /* line-height:0.3rem; */
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  color: white;
}

/* .big-text {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 2%;
  margin-bottom: 7%;
  max-width: 821px;
} */

/* .very-big-text {
  font-size: clamp(2rem, 3.5vw, 4rem);
  line-height: clamp(3rem, 5vw, 80px);
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  max-width: 40vw;
} */

.btn-md {
  width: 40%;
  margin-right: 5px;
  border: none;
}

.pink {
  background-color: #f02657;
  border: none;
}

.light-blue {
  background-color: #35aef9;
  border: none;
}

.body-text {
  font-size: clamp(1.5rem, 2.2vw, 2rem);
  line-height: clamp(2rem, 3vw, 36px);
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.title {
  font-size: clamp(1.5rem, 2.2vw, 2rem);
  line-height: clamp(1rem, 2vw, 2rem);
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

/* .italic-title {
  font-size: 32px;
  font-style: Poppins;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  display: inline;
} */

.small-italic-title {
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.paragraph {
  font-size: 1.05rem;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  max-width: 520px;
}

.footer-icon {
  width: 40px;
  height: 40px;
}

.accordion-button {
  color: #ffffff;
  background-color: transparent !important;
  border-radius: " 8px";
  border: "none";
}

.footer-bolded {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.see-all {
  cursor: pointer;
  background: linear-gradient(
    271.88deg,
    #3887fe 4.26%,
    #3ba0ff 51.37%,
    #5fb2ff 99.01%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
}

.about-img {
  /* max-width: 380px; */
  max-height: 650px;
  max-width: 450px;
}

/* .feature-text {
  margin-top: 15%;
} */
/* .feature-icon-img {
  display: inline !important;
  max-width: 60px;
  max-height: 65px;
  margin-right: 2%;
  margin-top: -2%;
} */

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
  background-color: rgba(238, 241, 243, 0.1);
  color: white;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.align-left {
  align-items: end;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.video-intro-text-con {
  max-width: 90%;
}

.footer-text {
  max-width: 320px;
  color: white;
}

@media (max-width: 767px) {
  .footer-text {
    margin: auto;
    text-align: left;
  }
  /* .mail-form-container {
    width: 25rem !important;
  } */
  /* .intro-wisp-img {
    max-width: 100% !important;
  } */
}

/* @media (max-width: 500px) {
  .mail-form-container {
    width: 22rem !important;

    padding-left: 15px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
  }} */
@media (max-width: 500px) {
  /* .mail-form-container {
      width: 90vw !important;
    }
    .email-form-input {
      width: 100% !important;
    }
  } */
  /* .intro-2nd-text {
    margin-top: 2rem;
  } */

  /* .big-text {
    font-size: medium;
    line-height: clamp(2rem, 2vw, 36px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    max-width: 100%;
  } */

  /* .very-big-text {
    font-size: clamp(2rem, 4vw, 4rem);
    line-height: clamp(3rem, 5vw, 80px);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    max-width: 320px;
  } */

  .modal-video-close-btn {
    width: 20px;
  }

  .about-img {
    max-height: 400px;
    max-width: 500px;
  }

  .body-text {
    font-size: clamp(1.5rem, 2.2vw, 2rem);
    line-height: clamp(2rem, 2vw, 36px);
  }
}

.slogan {
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 35px;
  /* identical to box height */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  background: linear-gradient(
    271.88deg,
    #3887fe 4.26%,
    #3ba0ff 51.37%,
    #5fb2ff 99.01%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-shadow: 0px 5px 12px rgba(6, 5, 19, 0.25);
  border-radius: 5px;
}

/* .download-app-btn {
  width: 11rem;
  
} */
.downloadd-app-logo {
  /* max-width: 50%; */
  /* display: inline-block; */
  background-color: #090b22;
  /* margin-left: 0%;
  padding: 0%; */
}

.auth-logo {
  width: 100%;
  max-width: 12rem;
}

.auth-form-container {
  border-width: 5px;
  background: linear-gradient(180deg, #090b22 0%, rgba(28, 35, 64, 0) 100%);
  filter: drop-shadow(0px 25px 25px rgba(0, 3, 32, 0.5));
  border-radius: 8px;
  box-shadow: 0px 25px 25px 0px rgba(0, 3, 32, 0.5);
  /* background-color:  rgba(176, 189, 197, 0.053); */
  max-width: 800px;
}

.support-form-container {
  border-width: 5px;
  background: rgba(28, 35, 64, 0);
  filter: drop-shadow(0px 25px 25px rgba(0, 3, 32, 0.5));
  border-radius: 8px;
  box-shadow: 0px 25px 25px 0px rgba(0, 3, 32, 0.5);
  background-color: rgba(176, 189, 197, 0.053);
}

.welcome-text {
  font-size: 27px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.footer-logo {
  max-width: 200px;
}

.input,
.input:focus,
select,
.country-list {
  background: #2e3558 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
  border: none !important;
}

select {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
@media (min-width: 500px) and (max-width: 1100px) {
  /* .very-big-text {
    max-width: 420px;
  } */
}
/* @media (min-width: 800px) and (max-width: 1000px) {
  .big-text {
    font-size: 14px;
    line-height: 26px;
  }
} */
/* @media (min-width: 1000px) and (max-width: 1200px) {
  .big-text {
    font-size: 18px;
    line-height: 30px;
  }
} */

/* @media (min-width: 1400px) and (max-width: 1500px) {
  .big-text {
    max-width: 813px;
  }
} */
/* @media (min-width: 1200px) and (max-width: 1401px) {
  .big-text {
    font-size: 22px;
    line-height: 34px;
    max-width: 750px;
  }
} */

.react-tel-input .selected-flag,
.flag-dropdown {
  background: #2e3558 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px !important;
  border: none !important;
}

.react-tel-input .form-control {
  padding-top: 1.7rem !important;
  padding-bottom: 1.7rem !important;
  width: 100%;
  background: #2e3558 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
  border: none !important;
}

.auth-submit-button {
  background: linear-gradient(
    271.88deg,
    #3887fe 4.26%,
    #3ba0ff 51.37%,
    #5fb2ff 99.01%
  );
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  color: #ffffff;
  width: -webkit-fill-available;
  width: fill-available;
  width: -moz-available;
}

.or-text {
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.5;
}

.auth-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.auth-confirmation-icon {
  width: 10%;
  min-width: 2rem;
  max-width: 5rem;
}

.auth-confirmation-title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 54px;
  text-align: center;
  color: #35aef9;
  text-shadow: 0px 4px 20px rgba(1, 143, 255, 0.15);
}

.confirmation-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.auth-header-logo {
  width: 60%;
}

form,
input,
label,
p {
  color: white !important;
}

.auth-bg-image {
  background-image: url("./assets/images/auth-bg.svg");
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
}

input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616a8b;
}

.input:focus {
  border-bottom: 2px solid #1288e8;
}
/* .mail-subscription-container {
  background-color: rgba(238, 241, 243, 0.1);
  padding-top: 70px;
  padding-left: 10%;
  padding-right: 8%;
  margin-bottom: 15px;
  height: 339px;
} */
/* .mail-bg {
  /* background-image: url("../src/assets/images/mail-bg-light.png"); */
/*background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative !important;
} */
/* .column-pt {
  padding-top: 16px;
} */
/* .mail-title {
  font-size: 23px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
} */

/* .mail-subtitle {
  color: #b2afb6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
} */

/* .mail-form-container {
  background: #ffffff;
  box-shadow: 0px 22.7px 60.5333px rgba(47, 65, 88, 0.2);
  border-radius: 8px;
  padding-left: 25px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 0px;
  width: 31rem;
} */

#basic-addon2:focus {
  border-bottom: 2px solid #1288e8;
}

.mail-submit-btn {
  background-color: #f02657;
  padding-right: 15px;
  padding-bottom: 7px;
  border-radius: 8px !important;
  height: 50px;
  box-shadow: 0px 3.78333px 18.9167px rgba(110, 109, 192, 0.5);
  border-radius: 8px;
}

.mail-submit-btn-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  background-color: rgba(227, 227, 227, 0.5);
  color: #ffffff;
}

select {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.verification-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 54px;
  /* identical to box height */

  text-align: center;

  /* Main Blue */

  color: #35aef9;

  text-shadow: 0px 4px 20px rgba(1, 143, 255, 0.15);
}

.note {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 3px;
}

.linkspan {
  color: rgba(53, 174, 249, 1);
  text-decoration: none;
}

.welcome-subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.auth-signup-2-bg-image {
  background-image: url("./assets/images/auth-bg.svg");
  background-size: cover;
  /* height: 115vh; */
  background-repeat: no-repeat;
}

option:disabled {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616a8b !important;
}

.css-1jqq78o-placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616a8b !important;
}

.form-control:not([value="female"]) {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.form-control[value="female"] {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.pass-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #5fb2ff;
  text-decoration: none;
}

.PhoneInputInput,
.PhoneInputCountry {
  background-color: #2e3558 !important;
  border-width: 0px !important;
  box-shadow: none !important;
  padding-top: 1rem;
  padding-bottom: 1rem;

  padding-left: 5px;
  margin-right: 0px !important;
}

.PhoneInputCountry {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 1rem;
}

.PhoneInputInput {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.PhoneInputInput--focus {
  border-width: 0px !important;
  border: none !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background-color: transparent !important;
  border-width: 0px !important;
  box-shadow: none !important;
}

.css-13cymwt-control:focus {
  background-color: #2e3558 !important;
  border-width: 0px !important;
  box-shadow: none !important;
}

.css-13cymwt-control:hover {
  background-color: #2e3558 !important;
  border-width: 0px !important;
  box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.css-1dimb5e-singleValue {
  color: white !important;
}

.auth-bg-image {
  background-image: url("./assets/images/auth-bg.svg");
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
}

input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616a8b;
}

.input:focus {
  border-bottom: 2px solid #1288e8;
}

#basic-addon2:focus {
  border-bottom: 2px solid #1288e8;
}

select {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.verification-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 54px;
  /* identical to box height */

  text-align: center;

  /* Main Blue */

  color: #35aef9;

  text-shadow: 0px 4px 20px rgba(1, 143, 255, 0.15);
}

.note {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.linkspan {
  color: rgba(53, 174, 249, 1);
}

.welcome-subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.auth-signup-2-bg-image {
  background-image: url("./assets/images/auth-bg.svg");
  background-size: cover;
  /* height: 115vh; */
  background-repeat: no-repeat;
}

option:disabled {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616a8b !important;
}

.css-1jqq78o-placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616a8b !important;
}

.form-control:not([value="female"]) {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.form-control[value="female"] {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.help-footer {
  margin-top: 0.7rem !important;
}

.pass-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #5fb2ff;
  text-decoration: none;
}

.PhoneInputInput,
.PhoneInputCountry {
  background-color: #2e3558 !important;
  border-width: 0px !important;
  box-shadow: none !important;
  padding-top: 1rem;
  padding-bottom: 1rem;

  padding-left: 5px;
  margin-right: -1px !important;
}

.PhoneInputCountry {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 1rem;
}

.PhoneInputInput {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.PhoneInputInput--focus {
  border-width: 0px !important;
  border: none !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background-color: transparent !important;
  border-width: 0px !important;
  box-shadow: none !important;
}

.css-13cymwt-control:focus {
  background-color: #2e3558 !important;
  border-width: 0px !important;
  box-shadow: none !important;
}

.css-13cymwt-control:hover {
  background-color: #2e3558 !important;
  border-width: 0px !important;
  box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.css-1dimb5e-singleValue {
  color: white !important;
}

#react-select-3-listbox {
  background-color: #2e3558 !important;
}

select option {
  background-color: #2e3558 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../src/assets/img/bottom-arrow.svg") no-repeat;

  width: 20px;
  height: 20px;
  border-width: thin;
}

.css-1xc3v61-indicatorContainer svg {
  color: #2fb2ff;
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

.bi-eye-slash-fill {
  margin-right: 10px;
}

input[type="date"] {
  padding-right: 15px;
}

.uploadImage {
  border-radius: 50%;
}

.uploadImageButton {
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #2fb2ff;
}

.removeUploadedImage {
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #f02657;
}

.or {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffffcc;
}

.vi__wrapper .vi {
  display: none;
}

.ReactInputVerificationCode__item {
  background: #2e3558 !important;
  width: 4rem !important;
  height: 3.5rem !important;
  font-weight: 400 !important;
  box-shadow: none !important;
  line-height: 50px !important;
  font-size: 15px !important;
  margin-right: 5px;

  padding: 10px;
}

.hLNLVq {
  width: 100% !important;
}

@media (max-width: 500px) {
  .ReactInputVerificationCode__item {
    width: 3.5rem !important;
    height: 2.5rem !important;
    line-height: 37px !important;
  }

  .auth-logo {
    max-width: 10rem;
  }
}

.input-group {
  border-radius: 8px;
}

textarea:focus,
input:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: #ffffff;
}

.icon-suffix {
  display: none;
}

.VVYPM {
  border-bottom: none !important;
  border-bottom-width: 0px !important;
}

.read-or-hide {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.0125em;
  text-align: left;
  color: #2fb2ff;
}

/* .mail-subscription-container {
  height: 341px;
} */

ul li {
  padding-top: 5px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  list-style-type: circle;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 30px;
  background: rgba(255, 255, 255, 0.4);
  color: white;
  cursor: pointer;
  border-radius: 50px;
  padding: 1px 16px 8px;
  background: rgb(94, 93, 93);
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
}

.back-to-top:hover {
  background: linear-gradient(
    271.88deg,
    #3887fe 4.26%,
    #3ba0ff 51.37%,
    #5fb2ff 99.01%
  );
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: #15183a !important;
}

.popover {
  background-color: #15183a !important;
  --bs-popover-bg: #15183a !important;
}

.filtration-text,
.filtration-text:hover {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
}

.dash {
  width: 10%;
  max-width: 60px;
  min-width: 20px;
  height: 4px;
  background: #ffffff;
  border-radius: 50px;
  margin-right: 0.5rem;
}

.confirmation-msg {
  background-color: #090b22;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 8px;
}

.confirmation-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.015em;
  text-align: left;
}

.confirmation-button {
  background: url(./assets/images/light-button-bg.png) no-repeat;
  cursor: pointer;
  border: none;
  background-size: cover;
  width: 150px;
  padding: 0px !important;
  margin: 0px !important;
  height: 45px;
  backdrop-filter: blur(25px);
  border-radius: 24px;
  border: 1.5px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.03) 100%,
    rgba(255, 255, 255, 0.03) 100%
  );

  color: white;
}

.cancel-button {
  cursor: pointer;
  border: none;
  background-size: cover;
  width: 150px;
  padding: 0px !important;
  margin: 0px !important;
  height: 45px;
  backdrop-filter: blur(25px);
  border-radius: 24px;
  border: 1.5px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.03) 100%,
    rgba(255, 255, 255, 0.03) 100%
  );
}

.confirmation-overlay {
  background-color: rgba(238, 241, 243, 0.1);
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 35px;
  height: 22px;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.4s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 3px;
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 45px;
  transition: 0.4s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(239, 239, 239, 0.29);
  margin-left: 0% !important;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(2px - 8%);
  transform: translateX(115%);
}

.react-switch-label:active .react-switch-button {
  width: 10px;
}

.theme-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  padding-left: 3px;
  padding-right: 3px;
  color: white;
}

/* .left-section-icon {
  width: 28vw;
}

.left-section-icon-container {
  position: absolute;
  left: 0%;
  bottom: -80px;
}

.right-section-icon {
  width: 26vw;
  top: -40%;
}
.right-section-icon-container {
  position: absolute;
  top: -70px;
  right: -25px;
} */

/* .email-inner-submit-btn {
  background-color: #f02657 !important;
  border: none;
  width: 100px;
  position: relative;
  left: -5;
  z-index: 10;
  background-color: #f02657;
  box-shadow: 0px 3.78333px 18.9167px rgba(110, 109, 192, 0.5);
  border-radius: 8px;
  height: 100%;
}
.email-inner-submit-btn div {
  margin-left: 13px;
} */
/* .text-danger {
  font-size: 10px;
  height: 18px;
} */

/* .after-subscribe-container {
  height: 48px;
  padding-top: 10px;
} */
/* .after-subscribe-span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  color: #090b22;
} */

/* .email-button-text {
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 30px !important;
  color: #ffffff;
} */

/* .email-input-text {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: black !important;

  height: 48px;
  appearance: none;
  background-color: rgba(227, 227, 227, 0.5);
  box-shadow: inset 0px 0px 0px 0px red;
  border: none;
} */

/* @media only screen and (min-width: 992px) {
  .info-phone-img {
    padding-right: 12%;
    padding-top: -40 !important;
  }
} */

/* @media only screen and (max-width: 1550px) {
  .mail-form-container {
    width: 32vw;
  }

  .right-section-icon-container {
    top: -70;
    right: -25;
  }
} */

/* @media only screen and (max-width: 1549px) and (min-width: 1408px) {
  .left-section-icon {
    width: 27vw;
  }

  .left-section-icon-container {
    left: -2%;
    bottom: -80px;
  }
}

@media only screen and (max-width: 1408px) and (min-width: 1380px) {
  .left-section-icon {
    width: 316px;
  }

  .left-section-icon-container {
    left: 0%;
    bottom: -94px;
  }
}

@media only screen and (max-width: 1379px) and (min-width: 1250px) {
  .left-section-icon {
    width: 302px;
  }

  .left-section-icon-container {
    left: 0;
    bottom: -98px;
  }
} */

/* @media only screen and (max-width: 1250px) and (min-width: 1200px) {
  .left-section-icon {
    width: 27vw;
  }

  .left-section-icon-container {
    left: -5%;
    bottom: -90px;
  }
  .mail-subscription-container {
    height: 339px;
  }
} */

/* @media only screen and (max-width: 1200px) and (min-width: 1050px) {
  .left-section-icon {
    width: 295px;
  }

  .left-section-icon-container {
    left: -3%;
    bottom: -98px;
  }
  .mail-subscription-container {
    height: 339px;
  }
} */

/* @media only screen and (max-width: 1050px) and (min-width: 1000px) {
  .left-section-icon {
    width: 27vw;
  }

  .left-section-icon-container {
    left: -4%;
    bottom: -104px;
  }
  .mail-subscription-container {
    height: 339px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 872px) {
  .mail-subscription-container {
    height: 295px;
  }

  .left-section-icon {
    width: 188px;
  }

  .right-section-icon {
    width: 20vw;
  }
  .mail-subscription-container {
    height: 295px;
  }

  .left-section-icon-container {
    left: -4%;
    bottom: -90px;
  }

  .right-section-icon-container {
    right: -3%;
    top: -16px;
  }
  .mail-form-container {
    width: 44vw;
  }
}

@media only screen and (max-width: 872px) and (min-width: 768px) {
  .mail-subscription-container {
    height: 295px;
  }

  .left-section-icon {
    width: 181px;
  }

  .right-section-icon {
    width: 20vw;
  }
  .mail-subscription-container {
    height: 291px;
  }

  .left-section-icon-container {
    left: -4%;
    bottom: -88px;
  }

  .right-section-icon-container {
    right: -3%;
    top: -16px;
  }
  .mail-form-container {
    width: 44vw;
  }
} */

/* @media only screen and (max-width: 767px) {
  .mail-subscription-container {
    height: 295px;
  }

  .left-section-icon {
    width: 22vw;
  }
  .mail-subscription-container {
    height: 384px;
  }

  .left-section-icon-container {
    display: none !important;
  }
  .right-section-icon-container {
    display: none !important;
  }

  .intro-wisp-img {
    max-width: 100%;
    width: 100%;
  }
}

.top-section-icon-container {
  position: absolute;
  right: 66px;
  top: -25px;
} */

/* .top-section-icon {
} */

/* .bottom-section-icon {
  width: 26vw;
  bottom: -48px;
  height: 124px;
}
.bottom-section-icon-container {
  position: absolute;
}

@media only screen and (max-width: 765px) and (min-width: 605px) {
  .bottom-section-icon {
    width: 32vw;
  }
  .top-section-icon-container {
    right: 5px;
    top: -16px;
  }
  .bottom-section-icon-container {
    position: absolute;
    bottom: -125px;
    left: 1px;
  }
}

@media only screen and (max-width: 604px) and (min-width: 525px) {
  .bottom-section-icon {
    width: 32vw;
  }
  .top-section-icon-container {
    right: 5px;
  }
  .bottom-section-icon-container {
    position: absolute;
    bottom: -125px;
    left: 1px;
  }
}

@media only screen and (max-width: 525px) and (min-width: 490px) {
  .bottom-section-icon {
    width: 32vw;
  }
  .top-section-icon-container {
    right: 4px;
  }

  .bottom-section-icon-container {
    position: absolute;
    bottom: -133px;
    left: -13px;
  }
} */

/* @media only screen and (max-width: 490px) and (min-width: 388px) {
  .bottom-section-icon {
    width: 45vw;
  }
  .top-section-icon-container {
    right: -8px;
  }

  .mail-title {
    font-size: 19px;
  }

  .bottom-section-icon-container {
    left: -9px;

    bottom: -126px;
  }

  .email-inner-submit-btn {
    background-color: #f02657;
  }

  .after-subscribe-container {
    height: 48px;
  }
} */

/* @media only screen and (max-width: 388px) and (min-width: 375px) {
  .bottom-section-icon {
    width: 330px;
  }
  .top-section-icon-container {
    right: -8px;
  }

  .mail-title {
    font-size: 19px;
  }

  .bottom-section-icon-container {
    left: -70px;
    bottom: -132px;
  }

  .email-input-text {
    height: 40px;
  }

  .email-inner-submit-btn {
    background-color: #f02657;
  }

  .after-subscribe-container {
    height: 40px;
  }

  .mail-submit-btn {
    height: 40px;
    background-color: #f02657;
  }
} */
/* @media (max-width: 300px) {
  .email-inner-submit-btn {
    width: 80px;
    height: auto;
    margin-top: 2%;
  }
  .email-input-text {
    width: 90% !important;
    border-radius: 10px !important;
  }
  .mail-btn-div {
    width: 100%;
    text-align: right;
  }
} */

/* @media only screen and (max-width: 375px) and (min-width: 355px) {
  .mail-form-container {
    width: 19rem !important;
  }

  .bottom-section-icon {
    width: 50vw;
  }
  .top-section-icon-container {
    right: -8px;
  }

  .mail-title {
    font-size: 16px;
  }

  .bottom-section-icon-container {
    left: 0px;
    bottom: -133px;
  }

  .email-input-text {
    height: 40px;
  }

  .email-inner-submit-btn {
    height: 40px;
    background-color: #f02657;
  }

  .after-subscribe-container {
    height: 40px;
  }

  .mail-submit-btn {
    height: 40px;
    background-color: #f02657;
  }
} */
/* @media (max-width: 355px) {
  .top-section-icon-container {
    display: none;
  }
  .bottom-section-icon-container {
    left: 0px;
    bottom: -109px;
  }
  .bottom-section-icon {
    width: 45vw;
  }
} */

/* @media only screen and (max-width: 992px) {
  .download-app-btn {
    width: 9rem;
  }
}
@media only screen and (max-width: 300px) {
  .download-app-btn {
    max-width: 7rem;
  }
} */

/* 
@media only screen and (max-width: 1549px) and (min-width: 1484px) {
  .mail-form-container {
    width: 32vw;
  }
}

@media only screen and (max-width: 1484px) and (min-width: 1413px) {
  .mail-form-container {
    width: 30vw;
  }
}

@media only screen and (max-width: 1413px) and (min-width: 1365px) {
  .mail-form-container {
    width: 28vw;
  }
}

@media only screen and (max-width: 1365px) and (min-width: 883px) {
  .mail-form-cntainer {
    width: 27vw;
  }
} */

@media (min-width: 576px) {
  .custom-container-sm {
    width: auto;
    max-width: none;
    padding-left: 15px;
    padding-right: 15px;
  }
}
